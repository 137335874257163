<div class="profile-menu">
  <button
    #profileMenuOption="matMenuTrigger"
    [matMenuTriggerFor]="profileMenu"
    class="profile-button"
  >
    @if (profileData().avatar) {
      <img class="avatar" [src]="profileData().avatar" alt="avatar" />
    } @else {
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2Z"
          stroke="#050609"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M4.27148 18.3457C4.27148 18.3457 6.5005 15.5 12.0005 15.5C17.5005 15.5 19.7296 18.3457 19.7296 18.3457"
          stroke="#050609"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12 12C13.6569 12 15 10.6569 15 9C15 7.34315 13.6569 6 12 6C10.3431 6 9 7.34315 9 9C9 10.6569 10.3431 12 12 12Z"
          stroke="#050609"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    }

    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      [ngClass]="{
        arrow: true,
        opened: profileMenuOption.menuOpen,
      }"
    >
      <path
        d="M4 6L8 10L12 6"
        stroke="#050609"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </button>

  <mat-menu #profileMenu="matMenu" class="profile-menu-list">
    @for (menuItem of menuList(); track menuItem.id) {
      <a
        [routerLink]="[menuItem.link]"
        routerLinkActive="active"
        class="item"
        >{{ menuItem.label }}</a
      >
    }

    <button (click)="logOut()" class="item btn">Выход</button>
  </mat-menu>
</div>
