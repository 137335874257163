import {
  ChangeDetectionStrategy,
  Component,
  inject,
  linkedSignal,
} from '@angular/core';
import { ReferencesService } from '@services/references.service';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ROUTE_PROFILE, ROUTE_PROFILE_PRICE_EDIT } from '@settings/routes';
import { toSignal } from '@angular/core/rxjs-interop';
import { map } from 'rxjs';

@Component({
  selector: 'app-price-edit',
  imports: [RouterLink],
  templateUrl: './price-edit.component.html',
  styleUrl: './price-edit.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriceEditComponent {
  references = inject(ReferencesService);
  router = inject(Router);
  route = inject(ActivatedRoute);

  editPriceLink = `/${ROUTE_PROFILE}/${ROUTE_PROFILE_PRICE_EDIT}`;

  code = toSignal<string | undefined>(
    this.route.queryParams.pipe(map((qparams): string => qparams['code'])),
  );

  currentCategory = linkedSignal<string | undefined, string>({
    source: this.code,
    computation: (queryCode) => {
      if (queryCode) {
        const found = this.references
          .categoriesList()
          .find((c) => c.code === queryCode);
        if (found) return found.code;
      }
      return this.references.categoriesList()[0].code;
    },
  });
}
