import { Component } from '@angular/core';

@Component({
  selector: 'app-zakroma-vertical-icon',
  imports: [],
  styleUrl: './icons.component.scss',
  template: `<svg
    xmlns="http://www.w3.org/2000/svg"
    width="118"
    height="60"
    viewBox="0 0 118 60"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M47.5526 2.35442C51.2613 0.799678 55.236 0 59.2501 0C63.2642 0 67.2388 0.799678 70.9476 2.35442C74.6557 3.90848 78.0257 6.18652 80.8639 9.05806C83.7021 11.9296 85.9537 15.3392 87.4897 19.0909C89.0264 22.8432 89.8168 26.8646 89.8168 30.9258V33.3931H48.6637L48.8681 30.7345C49.3505 24.4628 50.7482 19.1727 53.6499 15.4163C56.6618 11.5173 61.032 9.59884 66.6329 9.59884H68.9933L69.0702 11.9857C69.153 14.5524 68.4689 18.3297 66.0122 21.3757C63.6383 24.3189 59.8547 26.2758 54.3988 26.0777C54.2485 26.8301 54.1176 27.623 54.006 28.4585H84.8225C84.5807 25.8912 83.962 23.3705 82.9832 20.9795C81.6924 17.8261 79.8002 14.9607 77.4144 12.5474C75.0292 10.1342 72.197 8.21917 69.0802 6.91319C65.9634 5.60721 62.6228 4.93461 59.2494 4.93461C55.8761 4.93461 52.5354 5.60721 49.4186 6.91319C46.3018 8.21917 43.4697 10.1335 41.0845 12.5474C38.6993 14.9613 36.8065 17.8261 35.5156 20.9795C34.2228 24.1377 33.0589 29.9855 33.0589 33.3931H28.1816C28.1816 29.3265 29.4751 22.8385 31.0098 19.0909C32.5458 15.3385 34.7974 11.9296 37.6356 9.05806C40.4738 6.18652 43.8438 3.90848 47.5519 2.35442H47.5526ZM55.9128 21.1397C59.1906 20.9687 61.0901 19.6742 62.2333 18.2567C63.0878 17.1975 63.6183 15.9442 63.9096 14.745C61.0226 15.2284 58.9855 16.5228 57.4929 18.4548C56.9016 19.22 56.3745 20.1129 55.9128 21.1391V21.1397Z"
      fill="#6F9305"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M115.677 56.687H103.797C103.797 53.5979 104.064 50.8102 105.494 48.972C106.752 47.3559 108.803 46.5131 110.772 46.7817C110.938 48.0912 110.621 49.8954 109.604 51.1468C109.065 51.81 108.002 52.8928 105.699 53.2051H115.677V56.687Z"
      fill="#050609"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M97.6342 46.4609V59.3114H93.9266V50.2938H93.7559C92.3316 50.2938 91.1837 51.4586 91.1837 52.8909V55.0698H87.4734V52.8909C87.4734 51.4586 86.3255 50.2938 84.9012 50.2938H84.7304V59.3114H81.0195V46.4616H84.8334C86.178 46.465 87.4269 46.8906 88.4553 47.6129H88.4566C88.7682 47.8338 89.0598 48.0818 89.3269 48.3541C89.5946 48.0818 89.8869 47.8331 90.2018 47.6129C91.2302 46.8906 92.4797 46.465 93.825 46.465H93.8257L97.6342 46.4609Z"
      fill="#050609"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M73.7367 46.4609C70.2151 46.4609 67.3516 49.3359 67.3516 52.8879C67.3516 56.4398 70.2158 59.3114 73.7367 59.3114C77.2577 59.3114 80.1252 56.4365 80.1252 52.8879C80.1252 49.3393 77.2617 46.4609 73.7367 46.4609ZM73.8069 55.4851C72.3751 55.4851 71.2206 54.3169 71.2206 52.8879C71.2206 51.4588 72.3758 50.2866 73.8069 50.2866C75.238 50.2866 76.3938 51.4548 76.3938 52.8879C76.3938 54.3209 75.2393 55.4851 73.8069 55.4851Z"
      fill="#050609"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M61.8917 46.4643C61.8412 46.4609 61.7946 46.4609 61.77 46.4609H56.7693C54.1669 46.4609 52.0268 48.49 51.8266 51.0744C51.8119 51.2149 51.8086 51.3494 51.8086 51.4737V59.3114H55.5247V56.3229C55.9225 56.4277 56.3389 56.4831 56.7693 56.4831H56.7747C56.7979 56.4831 56.8412 56.4831 56.8884 56.4804L61.77 56.4831C61.7946 56.4831 61.8412 56.4831 61.8917 56.4797C64.5707 56.4135 66.7261 54.2 66.7261 51.4737C66.7261 48.7474 64.5713 46.5312 61.8917 46.4643ZM63.0672 51.5027C63.0672 52.0176 62.7379 52.4723 62.2782 52.6372L62.2729 52.6392L62.2676 52.6412C62.1445 52.6885 62.0048 52.7149 61.8631 52.7149H56.8678C56.6556 52.7149 56.462 52.6608 56.3003 52.5683L56.2943 52.5649L56.289 52.5615C55.9145 52.3581 55.6637 51.9588 55.6637 51.5027C55.6637 51.0467 55.9039 50.6602 56.2704 50.4507H56.2717L56.2731 50.4487C56.448 50.3474 56.6483 50.2899 56.8678 50.2899H61.8631C62.0148 50.2899 62.1585 50.3183 62.2902 50.371L62.2935 50.3724L62.2969 50.3737C62.7492 50.5467 63.0672 50.9865 63.0672 51.5027Z"
      fill="#050609"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M50.737 57.1297V59.3114H46.9947V57.1331C46.9947 55.7 45.8371 54.5351 44.4007 54.5351C43.5325 54.5351 41.4363 54.523 40.0977 54.5135V59.3114H36.3555V46.4609H40.0977V50.5879C40.3617 50.592 40.6457 50.6014 40.9465 50.6129L42.202 46.4609H45.9892L44.7035 50.7136C48.0646 50.9075 50.737 53.7034 50.737 57.1297Z"
      fill="#050609"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M34.6627 55.4946C33.4436 55.2834 32.522 54.2076 32.522 52.9212C32.522 51.3652 31.9795 49.9356 31.0785 48.8224C30.1403 47.6542 28.8044 46.829 27.2786 46.5587H27.2779C26.9214 46.4963 26.5528 46.4609 26.1764 46.4609C22.6726 46.4609 19.8301 49.3515 19.8301 52.9212C19.8301 56.491 22.6732 59.3781 26.1764 59.3781C27.9326 59.3781 29.5215 58.6514 30.6688 57.4778C31.6754 58.5068 33.0233 59.1927 34.5219 59.3435L35.1049 59.4019V55.5714L34.6627 55.4946ZM26.1179 50.3098C26.1604 50.3078 26.2029 50.3064 26.2454 50.3064C26.5196 50.3064 26.7819 50.3492 27.0269 50.4287H27.0276C28.066 50.7655 28.8163 51.7571 28.8163 52.9212C28.8163 54.3577 27.6683 55.532 26.2454 55.532C24.8225 55.532 23.6752 54.3577 23.6752 52.9212C23.6752 52.6007 23.7323 52.2937 23.8365 52.0098C24.1858 51.0562 25.0682 50.3614 26.1179 50.3098Z"
      fill="#050609"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.9372 52.925C18.9372 56.4794 16.1168 59.363 12.6281 59.4018H6.3845C2.86174 59.4018 0 56.5045 0 52.925V52.3803H3.86596V52.925C3.86596 54.3474 4.99102 55.5111 6.39051 55.5458H12.6228C14.0543 55.5458 15.2108 54.3713 15.2108 52.9284C15.2108 51.4855 14.055 50.3103 12.6234 50.3103H12.6228L10.203 50.3144V46.4516H12.6702C13.0007 46.4516 13.2651 46.1806 13.2651 45.8483C13.2651 45.516 13.0127 45.2593 12.6895 45.2491L12.6755 45.2518H10.1029C9.78908 45.2729 9.54804 45.5337 9.54804 45.8517V46.3964H5.70412V45.8517C5.70412 43.3881 7.66581 41.4006 10.0869 41.3931H12.6228C15.0478 41.3931 17.0169 43.3847 17.0169 45.8483C17.0169 46.5755 16.8446 47.2618 16.5415 47.8672C18.0004 49.0526 18.9372 50.8754 18.9372 52.925Z"
      fill="#050609"
    />
    <path
      d="M99.0625 51.043C99.0625 45.7138 103.302 41.3931 108.531 41.3931C113.76 41.3931 118 45.7138 118 51.043V59.4018H114.132V51.043C114.132 47.8905 111.624 45.3346 108.531 45.3346C105.438 45.3346 102.93 47.8905 102.93 51.043V59.4018H99.0625V51.043Z"
      fill="#050609"
    />
  </svg>`,
})
export class ZakromaVerticalIconComponent {}
