<app-external-layout>
  <form class="full-screen-form auth">
    <div class="title">Вход</div>
    <app-label label="Выполните вход в аккаунт" />

    @if (!!error()) {
      <app-error [errorText]="error()" />
    }

    <app-input-phone
      name="phone_number"
      id="phone_number"
      #phone_number="ngModel"
      [(ngModel)]="loginForm.phone_number"
      [required]="true"
      [pattern]="phonePattern"
      [showMask]="false"
      [disabled]="loading()"
      [error]="(phone_number.invalid && phone_number.touched) || !!error()"
      class="mb-24"
    />

    <app-input-password
      name="password"
      id="password"
      #password="ngModel"
      [(ngModel)]="loginForm.password"
      [minlength]="8"
      [required]="true"
      label="Пароль"
      [disabled]="loading()"
      [error]="(password.invalid && password.touched) || !!error()"
      class="mb-16"
    />

    <app-link
      [link]="passwordRecoveryRoute"
      description="Восстановить пароль"
      class="mt-16"
    />

    <div class="recaptcha-block mt-32">
      <re-captcha
        #captcha
        (resolved)="resolved($event)"
        [siteKey]="key"
      ></re-captcha>
    </div>

    <app-button
      variety="secondary"
      width="stretch"
      type="submit"
      class="submit-button mb-24"
      (pressed)="signIn()"
      [disabled]="
        !!phone_number.invalid ||
        !!password.invalid ||
        loading() ||
        loginForm.recaptcha === null
      "
      >Войти</app-button
    >

    <div class="link-block">
      Нет аккаунта?
      <app-button
        [routerLink]="registrationRoute"
        class="submit-button without-top-margin"
        width="stretch"
        variety="secondary"
        >Зарегистрироваться
      </app-button>
    </div>
  </form>
</app-external-layout>
