<h1 class="page-title">Редактирование цен</h1>
<h2 class="table-title">Таблица цен</h2>
<div class="view-by-block">
  @for (
    category of references.categoriesList();
    track category.code;
    let last = $last, first = $first
  ) {
    <button
      type="button"
      [routerLink]="editPriceLink"
      [queryParams]="{ code: category.code }"
      class="view-by"
      [class.left]="first"
      [class.middle]="!first && !last"
      [class.right]="last"
      [class.active]="category.code === currentCategory()"
    >
      {{ category.label }}
    </button>
  }
</div>
