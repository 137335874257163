@if (isMobile()) {
  <app-mobile-header variety="center">
    <app-link-back [link]="routeProfile" variety="absolute" />
    <app-title>Мои объявления</app-title>
  </app-mobile-header>
} @else {
  <app-title>Мои объявления</app-title>
}

<app-tabs
  id="tabs"
  [tabList]="tabList"
  [countsList]="myAnnouncementsStatusesCount()"
/>

<router-outlet />

@if (!profileService.isRecycler()) {
  <a [routerLink]="announcementCreateLink" class="announcement-create-link">
    <mat-icon fontIcon="add" class="plus"></mat-icon>
  </a>
}
