<div class="footer" id="footer">
  <div class="footer-content">
    <div class="content-block padding">
      <div class="footer-logo-wrapper">
        <app-logo-link
          fillLogo="#ffffff"
          fillText="#ffffff"
          class="footer-logo"
        />
      </div>
      @if (emails().length > 0) {
        <app-contact-block [contacts]="emails()"></app-contact-block>
      }
      <div class="technical-information">
        © {{ year }} Закрома, все права защищены
      </div>
    </div>

    <div class="double-content-block">
      <div class="footer-management margin">
        <div class="management-block-menu">
          @for (item of menuList(); track item) {
            <app-menu-link
              [menuItem]="item"
              variety="white"
              [adaptiveText]="true"
            ></app-menu-link>
          }
        </div>
        <div class="buttons-block">
          @if (false === isAuth()) {
            <app-button-link [link]="authLink"
              >Вход / регистрация</app-button-link
            >
          }
          <app-icon-button-link
            [link]="favouritesLink"
            kind="favorite"
          ></app-icon-button-link>
          <app-icon-button
            variety="dark"
            kind="up"
            (pressed)="scrollToTop()"
          ></app-icon-button>
        </div>
      </div>

      <div class="content-blocks">
        <div class="content-block">
          @if (phones().length > 0) {
            <app-contact-block
              variety="phone"
              [contacts]="phones()"
            ></app-contact-block>
          }
        </div>

        <div class="content-block wide">
          <app-search-with-button
            #footerSearch
            variety="dark"
            placeholder="Поиск по объявлениям"
            (search)="searchAnnouncements($event)"
          ></app-search-with-button>
          <div class="buttons" [class.wrap-link]="socials().length > 2">
            @if (profileService.isRecycler()) {
              <app-button-link
                [link]="editPriceLink"
                variety="secondary"
                width="stretch"
                class="announcement-button"
                >Перейти в прайс</app-button-link
              >
            } @else {
              <app-button-link
                [link]="announcementCreateLink"
                variety="secondary"
                width="stretch"
                class="announcement-button"
                >Разместить объявление</app-button-link
              >
            }

            @if (socials().length > 0) {
              @for (social of socials(); track social) {
                <a [href]="social.url" target="_blank" class="social-link">
                  <img [src]="social.icon" alt="icon" />
                </a>
              }
            }
          </div>
        </div>
      </div>

      <div class="footer-management">
        <div class="technical-links-block">
          @for (link of links(); track link.url) {
            <a
              routerLink="{{ pagesRoute + link.url }}"
              class="technical-information link"
              >{{ link.name }}</a
            >
          }
        </div>

        <a href="https://freematiq.com" class="dev-link" target="_blank">
          <div class="technical-information link">Разработано</div>
          <img src="/assets/icons/dev-logo.svg" alt="Freematiq" />
        </a>
      </div>
    </div>
  </div>
</div>
