import { Component, computed, input } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { NgClass } from '@angular/common';

import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';

import { headerProfileMenuList } from './headerProfileMenuList';

import { AuthService } from '@services/auth.service';
import { TProfileData } from '@models/profileTypes';

@Component({
  selector: 'app-profile-button',
  imports: [
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    RouterLink,
    NgClass,
    RouterLinkActive,
  ],
  templateUrl: './profile-menu.component.html',
  styleUrl: './profile-menu.component.scss',
})
export class ProfileMenuComponent {
  profileData = input.required<TProfileData>();

  menuList = computed(() => headerProfileMenuList(this.profileData().type));

  constructor(private authService: AuthService) {}

  logOut() {
    this.authService.logOut();
  }
}
