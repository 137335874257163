<div class="mobile-footer-menu">
  <button
    #menuOption="matMenuTrigger"
    [matMenuTriggerFor]="menu"
    [ngClass]="{
      'menu-button active': true,
      opened: menuOption.menuOpen,
    }"
  >
    <div class="image-wrapper">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="21"
        viewBox="0 0 22 21"
        fill="none"
        class="stroked"
      >
        <path
          d="M11.875 10.5C11.875 10.0168 11.4832 9.625 11 9.625C10.5168 9.625 10.125 10.0168 10.125 10.5C10.125 10.9832 10.5168 11.375 11 11.375C11.4832 11.375 11.875 10.9832 11.875 10.5Z"
          fill="#878787"
          stroke="#878787"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5.75 10.5C5.75 10.0168 5.35825 9.625 4.875 9.625C4.39175 9.625 4 10.0168 4 10.5C4 10.9832 4.39175 11.375 4.875 11.375C5.35825 11.375 5.75 10.9832 5.75 10.5Z"
          fill="#878787"
          stroke="#878787"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18 10.5C18 10.0168 17.6082 9.625 17.125 9.625C16.6418 9.625 16.25 10.0168 16.25 10.5C16.25 10.9832 16.6418 11.375 17.125 11.375C17.6082 11.375 18 10.9832 18 10.5Z"
          fill="#878787"
          stroke="#878787"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <div class="label">Меню</div>
  </button>

  <mat-menu #menu="matMenu" class="footer-menu-list">
    <a [routerLink]="profileOrAuthLink()" class="item top">
      @if (profileService.profileData()?.avatar) {
        <img
          class="avatar"
          [src]="profileService.profileData()?.avatar"
          alt="avatar"
        />
      } @else {
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
        >
          <g clip-path="url(#clip0_684_28930)">
            <path
              d="M10.5 1.75C5.66751 1.75 1.75 5.66751 1.75 10.5C1.75 15.3324 5.66751 19.25 10.5 19.25C15.3324 19.25 19.25 15.3324 19.25 10.5C19.25 5.66751 15.3324 1.75 10.5 1.75Z"
              stroke="#878787"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M3.73828 16.0525C3.73828 16.0525 5.68867 13.5625 10.5012 13.5625C15.3137 13.5625 17.2641 16.0525 17.2641 16.0525"
              stroke="#878787"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10.5 10.5C11.9498 10.5 13.125 9.32479 13.125 7.875C13.125 6.42526 11.9498 5.25 10.5 5.25C9.05021 5.25 7.875 6.42526 7.875 7.875C7.875 9.32479 9.05021 10.5 10.5 10.5Z"
              stroke="#878787"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_684_28930">
              <rect width="21" height="21" fill="white" />
            </clipPath>
          </defs>
        </svg>
      }
      <span>{{
        isAuth()
          ? "Профиль" + " " + profileService.profileData()?.first_name
          : "Войти"
      }}</span>
    </a>
    @if (!profileService.isRecycler()) {
      <a [routerLink]="announcementCreateLink" class="item">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
        >
          <path
            d="M7.875 10.5H13.125M10.5 7.875V13.125M2.625 10.5C2.625 11.5342 2.82869 12.5582 3.22445 13.5136C3.6202 14.4691 4.20027 15.3372 4.93153 16.0685C5.6628 16.7997 6.53093 17.3798 7.48637 17.7756C8.44181 18.1713 9.46584 18.375 10.5 18.375C11.5342 18.375 12.5582 18.1713 13.5136 17.7756C14.4691 17.3798 15.3372 16.7997 16.0685 16.0685C16.7997 15.3372 17.3798 14.4691 17.7756 13.5136C18.1713 12.5582 18.375 11.5342 18.375 10.5C18.375 8.41142 17.5453 6.40838 16.0685 4.93153C14.5916 3.45469 12.5886 2.625 10.5 2.625C8.41142 2.625 6.40838 3.45469 4.93153 4.93153C3.45469 6.40838 2.625 8.41142 2.625 10.5Z"
            stroke="#878787"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <span>Разместить объявление</span>
      </a>
    } @else {
      <a [routerLink]="editPriceLink" class="item">
        <app-filter-icon baseColor="#878787" />
        <span>Перейти в прайс</span>
      </a>
    }
    <a [routerLink]="analysisLink" class="item">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
      >
        <path
          d="M2.625 2.625V18.375H18.375M17.5 15.75V18.375M14 14V18.375M10.5 11.375V18.375M7 14V18.375M2.625 9.625C7.875 9.625 7 5.25 10.5 5.25C14 5.25 13.125 9.625 18.375 9.625"
          stroke="#878787"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <span>Аналитика цен</span>
    </a>
    <a [routerLink]="mapUrl()" queryParamsHandling="merge" class="item">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
      >
        <path
          d="M9.625 15.75L7.875 14.875M7.875 14.875L2.625 17.5V6.125L7.875 3.5M7.875 14.875V3.5M7.875 3.5L13.125 6.125M13.125 6.125L18.375 3.5V10.0625M13.125 6.125V10.5M17.675 17.675L19.25 19.25M13.125 15.75C13.125 16.4462 13.4016 17.1139 13.8938 17.6062C14.3861 18.0984 15.0538 18.375 15.75 18.375C16.4462 18.375 17.1139 18.0984 17.6062 17.6062C18.0984 17.1139 18.375 16.4462 18.375 15.75C18.375 15.0538 18.0984 14.3861 17.6062 13.8938C17.1139 13.4016 16.4462 13.125 15.75 13.125C15.0538 13.125 14.3861 13.4016 13.8938 13.8938C13.4016 14.3861 13.125 15.0538 13.125 15.75Z"
          stroke="#878787"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <span>Объявления на карте</span>
    </a>
    <a
      [routerLink]="contactsLink"
      [ngClass]="{
        item: true,
        last: !isAuth(),
      }"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
      >
        <g clip-path="url(#clip0_684_28945)">
          <path
            d="M19.2498 14.8051V17.4301C19.2507 17.6738 19.2008 17.915 19.1032 18.1383C19.0056 18.3615 18.8624 18.562 18.6828 18.7267C18.5033 18.8915 18.2913 19.0169 18.0604 19.095C17.8296 19.173 17.585 19.202 17.3423 19.1801C14.6497 18.8875 12.0634 17.9675 9.79101 16.4938C7.67686 15.1504 5.88443 13.358 4.54101 11.2438C3.06224 8.96114 2.14197 6.3622 1.85476 3.65758C1.83289 3.41562 1.86165 3.17175 1.9392 2.94151C2.01674 2.71126 2.14138 2.49969 2.30518 2.32025C2.46897 2.14082 2.66834 1.99746 2.89058 1.89929C3.11281 1.80113 3.35306 1.75031 3.59601 1.75008H6.22101C6.64565 1.74591 7.05733 1.89628 7.3793 2.17318C7.70127 2.45007 7.91158 2.8346 7.97101 3.25508C8.0818 4.09514 8.28728 4.91997 8.58351 5.71383C8.70123 6.02702 8.72671 6.36738 8.65693 6.6946C8.58714 7.02182 8.42501 7.32218 8.18976 7.56008L7.07851 8.67133C8.32412 10.8619 10.1379 12.6757 12.3285 13.9213L13.4398 12.8101C13.6777 12.5748 13.978 12.4127 14.3052 12.3429C14.6325 12.2731 14.9728 12.2986 15.286 12.4163C16.0799 12.7126 16.9047 12.918 17.7448 13.0288C18.1698 13.0888 18.558 13.3029 18.8355 13.6304C19.113 13.9579 19.2604 14.376 19.2498 14.8051Z"
            stroke="#878787"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12.2949 1.75C14.0784 1.93792 15.7443 2.72895 17.0172 3.99226C18.29 5.25557 19.0936 6.91549 19.2949 8.6975"
            stroke="#878787"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12.2949 5.25C13.1555 5.41969 13.9452 5.84415 14.5615 6.46827C15.1778 7.09239 15.5923 7.88736 15.7512 8.75"
            stroke="#878787"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_684_28945">
            <rect width="21" height="21" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <span>Контакты</span>
    </a>

    @if (isAuth()) {
      <button (click)="logOutFunction()" class="item bottom">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
        >
          <path
            d="M12.25 7V5.25C12.25 4.78587 12.0656 4.34075 11.7374 4.01256C11.4092 3.68437 10.9641 3.5 10.5 3.5H4.375C3.91087 3.5 3.46575 3.68437 3.13756 4.01256C2.80937 4.34075 2.625 4.78587 2.625 5.25V15.75C2.625 16.2141 2.80937 16.6592 3.13756 16.9874C3.46575 17.3156 3.91087 17.5 4.375 17.5H10.5C10.9641 17.5 11.4092 17.3156 11.7374 16.9874C12.0656 16.6592 12.25 16.2141 12.25 15.75V14M7.875 10.5H18.375M18.375 10.5L15.75 7.875M18.375 10.5L15.75 13.125"
            stroke="#FF5B65"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <span class="out-span">Выйти</span>
      </button>
    }
  </mat-menu>
</div>
